<template>
  <v-container fluid>
    <v-row class="pa-5 primary bg-gradientClothing" dark  align="center" height="400">
            <v-col cols="col-md-6 d-none d-md-flex">
              <v-img
                src="/imgs/pre-primary-girls-shorts.png"
                aspect-ratio="1.9"
                contain
              ></v-img>
            </v-col>
            <v-col cols="col-md-6" justify="center">
              
              <h1 class="pink--text ozi-font text-md-h2 font-weight-black pb-5"><span class="onyx--text ozi-font">Dance Mouse </span>Clothing</h1>
              
              <p class="onyx--text d-none d-md-flex">DM Dance CO offers a variety of clothing for boys and girls! We also have a range of winter uniforms and an exciting new Dance Madness and Dance Mouse Ballerina outfit!</p>
              
            </v-col>
        </v-row>
        <!-- Acro Clothing -->
        <v-row class="pa-5 bg-white" dark  align="center" height="400">
            <v-col cols="12" justify="center" align="center">
              <h1 class="Onyx--text text-md-h2 font-weight-black pt-10"><span class="ozi-font">DM Acro</span></h1>
              <h1 class="lightCyanAcro--text ozi-font text-md-h2 font-weight-black pb-5"><span class="ozi-font">Clothing</span></h1>
            </v-col>
        </v-row>
        <v-row class="pa-5">
          <v-col
            v-for="(item,i) in acroImages"
            :key="i"
            class="d-flex child-flex"
            cols="col-sm-12 col-md-3"
          >
            <v-img
              :src="item.link"
              :lazy-src="item.link"
              aspect-ratio="1"
              class="grey lighten-2"
              contain
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <!-- Pre-Primary Clothing -->
        <v-row class="pa-5 bg-white" dark  align="center" height="400">
            <v-col cols="12" justify="center" align="center">
              <h1 class="Onyx--text text-md-h2 font-weight-black pt-10"><span class="ozi-font">Pre-Primary</span></h1>
              <h1 class="customPink--text ozi-font text-md-h2 font-weight-black pb-5"><span class="ozi-font">Clothing</span></h1>
            </v-col>
        </v-row>
        <v-row class="pa-5">
          <v-col
            v-for="(prepri,i) in prePriImages"
            :key="i"
            class="d-flex child-flex"
            cols="col-sm-12 col-md-3"
          >
            <v-img
              :src="prepri.link"
              :lazy-src="prepri.link"
              aspect-ratio="1"
              class="grey lighten-2"
              cover
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <!-- Pre-Primary Winter Clothing -->
        <v-row class="pa-5 bg-white" dark  align="center" height="400">
            <v-col cols="12" justify="center" align="center">
              <h1 class="Onyx--text text-md-h2 font-weight-black pt-10"><span class="ozi-font">Pre-Primary</span></h1>
              <h1 class="customPink--text ozi-font text-md-h2 font-weight-black pb-5"><span class="ozi-font">Winter Clothing</span></h1>
            </v-col>
        </v-row>
        <v-row class="pa-5">
          <v-col
            v-for="(item,i) in prePriWintImages"
            :key="i"
            class="d-flex child-flex"
            cols="col-sm-12 col-md-3"
          >
            <v-img
              :src="item.link"
              :lazy-src="item.link"
              aspect-ratio="1"
              class="grey lighten-2"
              cover
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <!-- Primary Clothing -->
        <v-row class="pa-5 bg-white" dark  align="center" height="400">
            <v-col cols="12" justify="center" align="center">
              <h1 class="Onyx--text text-md-h2 font-weight-black pt-10"><span class="ozi-font">Primary</span></h1>
              <h1 class="customPink--text ozi-font text-md-h2 font-weight-black pb-5"><span class="ozi-font">Clothing</span></h1>
            </v-col>
        </v-row>
        <v-row class="pa-5">
          <v-col
            v-for="(item,i) in priImages"
            :key="i"
            class="d-flex child-flex"
            cols="col-sm-12 col-md-3"
          >
            <v-img
              :src="item.link"
              :lazy-src="item.link"
              aspect-ratio="1"
              class="grey lighten-2"
              cover
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <!-- Primary Winter Clothing -->
        <v-row class="pa-5 bg-white" dark  align="center" height="400">
            <v-col cols="12" justify="center" align="center">
              <h1 class="Onyx--text text-md-h2 font-weight-black pt-10"><span class="ozi-font">Primary</span></h1>
              <h1 class="customPink--text ozi-font text-md-h2 font-weight-black pb-5"><span class="ozi-font">Winter Clothing</span></h1>
            </v-col>
        </v-row>
        <v-row class="pa-5">
          <v-col
            v-for="(item,i) in priWintImages"
            :key="i"
            class="d-flex child-flex"
            cols="col-sm-12 col-md-3"
          >
            <v-img
              :src="item.link"
              :lazy-src="item.link"
              aspect-ratio="1"
              class="grey lighten-2"
              cover
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <!-- Ballerina Clothing -->
        <v-row class="pa-5 bg-white" dark  align="center" height="400">
            <v-col cols="12" justify="center" align="center">
              <h1 class="Onyx--text text-md-h2 font-weight-black pt-10"><span class="ozi-font">Ballerina</span></h1>
              <h1 class="customPink--text ozi-font text-md-h2 font-weight-black pb-5"><span class="ozi-font">Clothing</span></h1>
            </v-col>
        </v-row>
        <v-row class="pa-5">
          <v-col
            v-for="(item,i) in balImages"
            :key="i"
            class="d-flex child-flex"
            cols="col-sm-12 col-md-3"
          >
            <v-img
              :src="item.link"
              :lazy-src="item.link"
              aspect-ratio="1"
              class="grey lighten-2"
              cover
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
  </v-container>
</template>

<script>



export default {
  data () {
    return {
      acroImages: [
          { title: 'Acro Back', link: '/imgs/acroClothing/ClothingShowcaseAcroBack.jpg' },
          { title: 'Acro Back and Front', link: '/imgs/acroClothing/ClothingShowcaseAcroBackFront.jpg' },
          { title: 'Acro Outfit 1', link: '/imgs/acroClothing/ClothingShowcaseAcroOutfit1.jpg' },
          { title: 'Acro Outfit 2', link: '/imgs/acroClothing/ClothingShowcaseAcroOutfit2.jpg' },
        ],
      prePriImages: [
          { title: 'Girls Clothing', link: '/imgs/prePriClothing/pre-primary-girls-clothing.jpg' },
          { title: 'Boys Clothing', link: '/imgs/prePriClothing/pre-primary-boys-clothing.jpg' },
          { title: 'Girls Bag', link: '/imgs/prePriClothing/pre-primary-bag-girls.jpg' },
          { title: 'Boys Bag', link: '/imgs/prePriClothing/pre-primary-bag-boys.jpg' },
        ],
        prePriWintImages: [
          { title: '', link: '/imgs/prePriWintClothing/pre-primary-girls-winter-fleece-top-front.jpg' },
          { title: '', link: '/imgs/prePriWintClothing/pre-primary-girls-winter-fleece-top-back.jpg' },
          { title: '', link: '/imgs/prePriWintClothing/pre-primary-boys-winter-fleece-top-front.jpg' },
          { title: '', link: '/imgs/prePriWintClothing/pre-primary-boys-winter-fleece-top-back.jpg' },
          { title: '', link: '/imgs/prePriWintClothing/pre-primary-girls-winter-tracksuit-front.jpg' },
          { title: '', link: '/imgs/prePriWintClothing/pre-primary-girls-winter-tracksuit-back.jpg' },
        ],
        priImages: [
          { title: '', link: '/imgs/priClothing/primary-girls-clothing.jpg' },
          { title: '', link: '/imgs/priClothing/primary-boys-clothing.jpg' },
          { title: '', link: '/imgs/priClothing/primary-bag.jpg' },
          { title: '', link: '/imgs/priClothing/primary-bottle.jpg' },
        ],
        priWintImages: [
          { title: '', link: '/imgs/priWintClothing/primary-girls-tracksuit-leggings.jpg' },
          { title: '', link: '/imgs/priWintClothing/primary-girls-winter-top.jpg' },
          { title: '', link: '/imgs/priWintClothing/primary-girls-winter-fleece-top-back.jpg' },
          { title: '', link: '/imgs/priWintClothing/primary-girls-winter-fleece-top-front.jpg' },
          { title: '', link: '/imgs/priWintClothing/primary-boys-winter-fleece-top-back.jpg' },
          { title: '', link: '/imgs/priWintClothing/primary-boys-winter-fleece-top-front.jpg' },
        ],
        balImages: [
          { title: '', link: '/imgs/balClothing/ballerina_leotard_tutu.jpg' },
          { title: '', link: '/imgs/balClothing/ballet_leotard_skirt_full.jpg' },
          { title: '', link: '/imgs/balClothing/ballet_preschool_leotard_skirt_full.jpg' },
          { title: '', link: '/imgs/balClothing/ballet_preschool_primary_jacket.jpg' },
          { title: '', link: '/imgs/balClothing/ballerina_preschool_tutu.jpg' },
          { title: '', link: '/imgs/balClothing/ballet_skirt.jpg' },
          { title: '', link: '/imgs/balClothing/ballet_leatard_skirt.jpg' },
          { title: '', link: '/imgs/balClothing/ballet_preschool_leotard.jpg' },
          { title: '', link: '/imgs/balClothing/ballerina_preschool_socks.jpg' },
          { title: '', link: '/imgs/balClothing/ballerina_bottle.jpg' },
          { title: '', link: '/imgs/balClothing/ballerina_preschool_primary_bag.jpg' },
        ],
    }
  },
  methods: {

    
      
  },

  computed: {

    
  },
}
</script>
<style>

.view-enter-active {
  animation-duration: 1.5s;
  animation-name: slideIn;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-delay: 1s ease-in;
}

@keyframes slideIn {
  from {
    opacity: 0;
    margin-left:-100%;
  }
  
  to {
    opacity: 1;
    margin-left:0%;
  }
}

.bg-grey {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-card.on-hover.theme--dark {
  transition: background-color 0.5s ease;
  background-color: rgba(#FFF, 0.8)
}

.bg-gradientClothing {
      background-image: linear-gradient(
  135deg
  ,#f342e4 25%,#ffb7ff 67%)!important;
}
</style>